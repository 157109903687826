/**
 * [通用] 日志记录调试信息
 */
import _ from 'lodash';

export function out(...rest: any[]): void {
  if (isDev()) {
    console.log(...rest);
  }
}

/**
 * 当前是否为开发者模式
 */
export function isDev(): boolean {
  if (localStorage.getItem('mock')) {
    return false;
  }
  if (window.origin.includes('http://localhost')) {
    return true;
  }
  if (localStorage.getItem('dev')) {
    return true;
  }
  return false;
}

/**
 * [通用] 等待
 * @param time 要等待的毫秒
 */
export async function wait(time = 1000, v?: unknown): Promise<typeof v> {
  return new Promise((reslove, reject) => {
    setTimeout(() => reslove(v), time);
  });
}

/**
 * [通用] 格式化日期为字符串
 */
export function formatDateString(
  format: 'yyyyMMdd' | 'yyyy-MM-dd',
  t: Date = new Date()
): string {
  const y = t.getFullYear().toString();
  const m = (t.getMonth() + 1).toString().padStart(2, '0');
  const d = `${t.getDate()}`.padStart(2, '0');
  if (format === 'yyyyMMdd') {
    return y + m + d;
  }
  if (format === 'yyyy-MM-dd') {
    return `${y}-${m}-${d}`;
  }
  return t.toISOString();
}

/**
 * [通用] 转为 number[] 类型
 */
export const toNumberArray = (str: string): number[] => {
  return JSON.parse(str).map(Number);
};

/**
 * [通用] 拆分数组 (用于替换 Arrar.filter)
 */
export function splitArray<T>(arr: T[], fn: (v: T) => boolean): [T[], T[]] {
  const res: [T[], T[]] = [[], []];
  for (const item of arr) {
    if (fn(item)) {
      res[0].push(item);
    } else {
      res[1].push(item);
    }
  }
  return res;
}

// ↓↓↓ DOM 处理函数集 ↓↓↓

/**
 * 将 HTMLOptionsCollection 转为数组
 * @param v 需要转为数组的 DOM
 * @param f 结果过滤函数, false 会被滤除
 */
export function mapOptions(
  v: HTMLOptionsCollection,
  f?: (v: HTMLOptionElement) => boolean
): HTMLOptionElement[] {
  const arr = [];
  for (let i = 0; i < v.length; i++) {
    if (typeof f !== 'function' || f(v[i])) {
      arr.push(v[i]);
    }
  }
  return arr;
}

// ↑↑↑ DOM 处理函数 ↑↑↑

/**
 * 渲染人民币分
 * @param fen 分
 */
export function renderRMBFen(
  fen: RMBFen,
  opts: {
    format?: FormatAmountDisplay;
  } = {}
): string {
  const { format } = opts;
  if (!Number.isFinite(fen)) {
    return '因分母为 0, 所以无法显示';
  }
  if (format === '整万') {
    return (fen / 1000000).toFixed(0) + '万';
  }
  if (format === '万') {
    if (fen > 1000000) {
      return (fen / 1000000).toFixed(0) + '万';
    }
    if (fen === 0) {
      return '0万';
    }
    return (fen / 1000000).toFixed(2) + '万';
  }
  if (format === '整元') {
    if (fen < 0) {
      return '-¥ ' + (-fen / 100).toFixed(0);
    }
    return '¥ ' + (fen / 100).toFixed(0);
  }
  if (format === '元') {
    return (fen / 100).toFixed(2);
  }
  return Number(fen / 100).toLocaleString('zh-CN', {
    style: 'currency',
    currency: 'CNY',
  });
}

/** 计算上级 ID 链 */
export function getPids(id: number, data: Level[]): number[] {
  const ids = [id];
  const fn = (id: number): void => {
    for (const v of data) {
      if (v.id === id) {
        ids.unshift(v.pid);
        fn(v.pid);
        return;
      }
    }
  };
  fn(id);
  return ids;
}

/** 计算所有下级 ID (包含本 ID) */
export function getCids(ids: number[], data: Level[]): number[] {
  const obj: {
    [key: string]: number;
  } = {};
  const fn = (id: number): void => {
    // 找到 id 的所有直接子类写入 obj
    for (const v of data) {
      if (v.pid === id && !obj[v.id]) {
        obj[v.id] = 1;
        fn(v.id);
      }
    }
  };
  for (const id of ids) {
    // 将 id 写入 obj
    if (!obj[id]) {
      obj[id] = 1;
    }
    fn(id);
  }
  return Object.keys(obj).map(Number);
}

function findUnequal(
  a: Record<string, string | number>,
  b: Record<string, string | number>
) {
  const arr = [];
  const keys = Object.keys(a);
  for (let i = 0, l = keys.length; i < l; i++) {
    const key = keys[i];
    if (!_.isEqual(a[key], b[key])) {
      arr.push(key);
    }
  }
  return arr;
}

/**
 * 找出不同的数据
 * @param a - 现在的数据
 * @param b - 之前的数据
 * @return a 的子集, 仅包含与 b 不同的项
 */
export function pickDiff<T extends Record<string, any>>(
  a: T,
  b: T
): Pick<T, string> | null {
  const data = _.pick(a, findUnequal(a, b));
  if (_.isEqual(data, {})) {
    return null;
  }
  return data;
}

/**
 * 字符串过滤
 */
export function strFilter(str: string): string {
  let n = '';
  for (let i = 0; i < str.length; i++) {
    const c = str.codePointAt(i) ?? 0;
    // 允许 a-z
    if (c >= 97 && c <= 122) {
      n += str[i];
    }
    // 允许 0-9
    if (c >= 48 && c <= 57) {
      n += str[i];
    }
  }
  return n;
}
