import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Link, Switch, Route } from 'react-router-dom';
import { isDev } from './functions';

const CustomerClient = React.lazy(() => import('./pages/CustomerClient'));
const InternalClient = React.lazy(() => import('./pages/InternalClient'));
const DingtalkClient = React.lazy(() => import('./pages/DingtalkClient'));

export default function App(): JSX.Element {
  return (
    <Router>
      {isDev() && (
        <>
          <ul>
            <li>
              <Link to="/c/">顾客端</Link>
            </li>
            <li>
              <Link to="/internal-only/">职员端</Link>
            </li>
            <li>
              <Link to="/dingtalk-only/">钉钉端</Link>
            </li>
          </ul>
          <hr />
        </>
      )}
      <React.Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/c" component={CustomerClient} />
          <Route path="/internal-only" component={InternalClient} />
          <Route path="/dingtalk-only" component={DingtalkClient} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

const rootElement = document.getElementById('root');
render(<App />, rootElement);
